import "./App.css";
import WrapComponent from "./Components/whiteBorad/WrapComponent";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route
          path="/*"
          element={<h2 style={{"fontWeight":"600"}}>Please choose a PDF file for editing</h2>}
        ></Route> */}
        <Route path="/" element={<WrapComponent />}></Route>
        <Route path="/*" element={<WrapComponent />}></Route>
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;

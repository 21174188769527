import React, { useEffect, useRef, useState } from "react";
const WrapperApp = () => {
  const [pdfLink, setPdfLink] = useState("");
  const urlImageRef = useRef();
  //   Taking the Query from URL as params

  const location = window.location.href;
  const pdfUrl = location.split("=");
  urlImageRef.current = pdfUrl[1];
  console.log(urlImageRef.current,"pdfurl");

  const pdfQuery = urlImageRef.current;

  useEffect(() => {
    handleWhiteBoard(pdfQuery);
  }, [pdfQuery]);

  const handleWhiteBoard = (pdfQuery) => {
  
    setPdfLink(pdfQuery);
  };

  const renderWhiteboardApp = () => {
    // Specific PDF URL=PDFLink is passed to the Editor App

      const whiteboardAppURL = `${process.env.REACT_APP_WHITEBOARD_URL}/?url=${pdfLink}`;
      return (
        <iframe
          src={whiteboardAppURL}
          title="Whiteboard App"
          width="100%"
          height="600"
          frameBorder="0"
        />
      );
  };
  return <div>{renderWhiteboardApp()}</div>;
};

export default WrapperApp;
